import React, { useState } from 'react';
import '../CSS/ContactUs.css';
import Logo from '../Images/logo.jpeg';
import Swal from 'sweetalert2';
import { FaBackward } from 'react-icons/fa6';
import { useForm } from '@formspree/react';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
    const navigate=useNavigate()
  const [formData, setFormData] = useState({
    orderName: 'General Message',
    fullName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [openSubmit, setOpenSubmit] = useState(false);
  const [state, handleSubmit] = useForm('mnqerjbz'); // Formspree form ID

  const handleReload = () => {
    Swal.fire({
      title: 'Loading',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timer: 1000,
    });
    Swal.showLoading();
    window.location.reload();
  };

  const handleLocation = () => {
    window.history.back();
  };

  const handleSubmit2 = (e) => {
    Swal.fire({
      title: 'Loading',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    Swal.showLoading();
    handleSubmit(e);
  };

  if (state.succeeded) {
    Swal.fire({ icon: 'success', showConfirmButton: false, timer: 2000 });
    return (
      <div className='FormSubmitUiWrap' >
        <div className='FormSubmitUi' style={{ width: '100%', height: '100vh' }}>
          <img src={Logo} alt='Logo' />
          <p style={{ width: '400px' }}>
            Your request has been submitted and one of our representatives will get back to you soon. Thank you.
          </p>
          <button onClick={()=>navigate("/")}>Ok</button>
        </div>
      </div>
    );
  }

  return (
    <div className='ContactUsWrap'>
      <div className='ContactUs'>
        <div className='ContactUsHeader'>
          <h1>Contact Us</h1>
        </div>
        <div className='ContactUsBody'>
          <div className='ContactUsBodyLeft'>
            <form onSubmit={handleSubmit2}>
              <input id='orderName' type='text' name='Order Name' value={formData.orderName} hidden />

              <label>
                <div className='InputLabelChild'>
                  {/* <p>Enter Full Name:</p> */}
                  <input
                    id='fullName'
                    type='text'
                    name='Full Name'
                    value={formData.fullName}
                    onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                    placeholder='Enter Your Full Name'
                    required
                  />
                </div>
              </label>

              <label>
                <div className='InputLabelChild'>
                  {/* <p>Enter Email address:</p> */}
                  <input
                    id='email'
                    type='email'
                    name='Email'
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    placeholder='Email Address'
                    required
                  />
                </div>
              </label>

              <label>
                <div className='InputLabelChild'>
                  {/* <p>Enter Phone number:</p> */}
                  <input
                    id='phoneNumber'
                    type='text'
                    name='Phone Number'
                    value={formData.phoneNumber}
                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                    placeholder='Phone number'
                    required
                  />
                </div>
              </label>

              
                <div className='InputLabelChild'>
                  {/* <p>Message:</p> */}
                  <textarea
                    id='message'
                    type='text'
                    name='Message'
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    placeholder='Please enter your message here'
                    required
                  />
                </div>
              

              <div className='SubmitButtonWrap'>
                <button type='submit' onClick={() => setOpenSubmit(true)}>
                  Submit
                </button>
              </div>

            
            </form>
          </div>

          <div className='ContactUsBodyRight'>
            <img src={Logo} alt='Contact us' />
          </div>
        </div>

        <div className='BackButtonWrap'>
          <button onClick={handleLocation}>
            <FaBackward /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
