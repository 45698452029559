import React from 'react'
import logo from "../Images/logo.png"
import '../CSS2/OurPartners.css'

const OurPartners = () => {
  return (
    <div className='ourpartnerswrap'>
        <h2>Our Partners</h2>
        <div className='ourpartners'>
            <img src={logo} alt='logo'/>
        </div>
    </div>
  )
}

export default OurPartners
