// import React from 'react'
// // import "../CSS/Hero.css"
// import "../CSS2/Hero2.css"

// const Hero = () => {
//   return (
//     <div className='HeroWrap'>
//         <div className='Hero'>
//             <h1>DIGITAL <br/> PREMIUM TECH,</h1>
//             <h2>Your best tech space for productivity and innovation</h2>
//         </div>
//         <span>
//             <a href="https://www.freepik.com/free-photo/3d-abstract-space-background-with-wireframe-landscape_4943498.htm#query=space%20tech%20background&position=1&from_view=keyword&track=ais&uuid=945d60cb-c300-401d-8f5a-6f8bd0faa6d4">Image by kjpargeter</a> on Freepik</span>
//     </div>
//   )
// }

// export default Hero


import React from 'react';
import "../CSS2/Hero2.css";

const Hero = () => {
  return (
    <div className='HeroWrap'>
      <div className='Overlay'></div> {/* Background overlay */}
      <div className='HeroContent'>
        <h1>DIGITAL <br /> PREMIUM TECH</h1>
        <h2>Your best tech space for productivity and innovation</h2>
        {/* <a className='HeroButton' href="#explore">Explore More</a> */}
      </div>
      <span className='ImageCredit'>
        <a href="https://www.freepik.com/free-photo/3d-abstract-space-background-with-wireframe-landscape_4943498.htm#query=space%20tech%20background&position=1&from_view=keyword&track=ais&uuid=945d60cb-c300-401d-8f5a-6f8bd0faa6d4">Image by kjpargeter</a> on Freepik
      </span>
    </div>
  );
}

export default Hero;

