import React, { useContext, useState } from 'react';
import "../CSS/FrontEndTrainingDetailPage.css";  // Use the same CSS file as FrontEndTraining
import TrainingPic1 from "../Images/Training pic 4.jpeg";
import { FaBackward } from 'react-icons/fa6';
import TermsAndConditions from './TermsAndConditions';
import { Context } from './Contex';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const UIUXTrainingDetailPage = () => {
    const { showTermsAndConditionsUI, setShowTermsAndConditionsUI } = useContext(Context);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        orderName: "UIUX/Product Design Training",
        fullName: "",
        email: "",
        phoneNumber: "",
        confirmPhoneNumber: "",
    });

    const [isSubmitted, setIsSubmitted] = useState(false);  // Track form submission
    const [isError, setIsError] = useState(false);  // Track errors in form submission

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.phoneNumber !== formData.confirmPhoneNumber) {
            Swal.fire({text:"Phone numbers does not match"})
            return;
        }

        const loadingAlert = Swal.fire({ text: "Please wait..." });
        Swal.showLoading();

        try {
            const response = await fetch('https://formspree.io/f/mnqerjbz', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderName: formData.orderName,
                    fullName: formData.fullName,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                }),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setFormData({
                    orderName: "UIUX/Product Design Training",
                    fullName: "",
                    email: "",
                    phoneNumber: "",
                    confirmPhoneNumber: "",
                });
                setIsError(false);
                Swal.fire({ icon: "success", text: "Your request has been submitted and one of our representatives shall get back to you soon" });
                navigate("/");
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        } finally {
            loadingAlert.close();
        }
    };

    return (
        <div className='FrontEndTrainingOfferWrap'>
            <div className='FrontEndTrainingOffer'>
                <div className='FrontEndTrainingOfferHeader'>
                    <h1>UIUX/Product Design Training</h1>
                </div>
                <div className='FrontEndTrainingImgWrap'>
                    <img src={TrainingPic1} alt="Training" />
                </div>
                <div className='FrontEndTrainingOfferBody'>
                    <div className='FrontEndTrainingOfferBodyRight'>
                        <p>
                            Learn UIUX/Product design with certification in 2 months with our comprehensive Training program for just 30,000 naira. Offer valid for first 10 people.
                            <br /><br />
                            <span style={{ textDecoration: "underline" }}>You shall learn the following and lots more:</span><br />
                            | User Experience | User Interface | Figma | Figjam |
                            <br /><br />
                            Fill in your details, and one of our representatives will contact you shortly. Thank you!
                        </p>
                    </div>
                    <div className='FrontEndTrainingOfferBodyLeft'>
                        <form onSubmit={handleSubmit}>
                            <div className='FrontEndTrainingformNote'>
                                <p>Please fill in the form, agree to the terms and conditions, and click submit.</p>
                                <p
                                    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={() => setShowTermsAndConditionsUI(true)}
                                >
                                    Click to read Terms and Conditions
                                </p>
                            </div>
                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input type="text" name="Order Name" value={formData.orderName} readOnly />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input
                                        type="text"
                                        name="Full Name"
                                        value={formData.fullName}
                                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                                        placeholder='Enter your Full Name'
                                        required
                                    />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input
                                        type="email"
                                        name="Email"
                                        value={formData.email}
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                        placeholder='Email Address'
                                        required
                                    />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input
                                        type="text"
                                        name='Phone Number'
                                        value={formData.phoneNumber}
                                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                        placeholder="Phone number"
                                        required
                                    />
                                </div>
                            </label>

                            <label>
                                <div className="FrontEndTrainingOfferInputLabelChild">
                                    <input
                                        type="text"
                                        value={formData.confirmPhoneNumber}
                                        onChange={(e) => setFormData({ ...formData, confirmPhoneNumber: e.target.value })}
                                        placeholder="Confirm phone number"
                                        required
                                    />
                                </div>
                            </label>

                            <div className='FrontEndTrainingOfferTermsAndConditionsAgreeWrap'>
                                <div className='FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp'>
                                    <input
                                        type="checkbox"
                                        required
                                    />
                                    <p>
                                        I agree to <span onClick={() => setShowTermsAndConditionsUI(true)}>Terms and Conditions</span>
                                    </p>
                                </div>
                            </div>

                            <div className='FrontEndTrainingOfferSubmitButtonWrap'>
                                <button type="submit">Submit</button>
                            </div>
                        </form>

                        {isSubmitted && <p style={{ color: "green" }}>Thank you! Your submission has been received.</p>}
                        {isError && <p style={{ color: "red" }}>There was an error submitting the form. Please try again.</p>}
                    </div>
                </div>
                <br /><br />
                <div className='FrontEndTrainingOfferBackButtonWrap'>
                    <button onClick={() => window.history.back()}>
                        <FaBackward /> Back
                    </button>
                </div>
            </div>
            {showTermsAndConditionsUI && <TermsAndConditions />}
        </div>
    );
};

export default UIUXTrainingDetailPage;
