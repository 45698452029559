import React from 'react';
import { FaDharmachakra, FaMobile,FaBullhorn, FaUsers, FaAssistiveListeningSystems, FaProjectDiagram, FaMoneyCheck, FaDev, FaMoneyBill } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import "../CSS2/Gigs2.css";

const Gigs = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate("/contactus");
    }

    return (
        <div className='OurServicesWrap'>
            <div className='OurServices'>
                <div className='OurServicesHeading'>
                    <h1>Our Services</h1>
                </div>
                <div className='OurServicesBody'>
                    <div className='OurService'>
                        <FaDharmachakra className='OurServiceBodyIcon' />
                        <h2>Custom Software and Web Development</h2>
                        <p>We offer tailored solutions for websites, web applications, portfolio sites, and more to meet your unique needs.</p>
                    </div>

                    <div className='OurService'>
                        <FaMobile className='OurServiceBodyIcon' />
                        <h2>Mobile App Development</h2>
                        <p>We build intuitive, feature-rich mobile apps for iOS, Android, and other platforms.</p>
                    </div>

                    <div className='OurService'>
                        <FaUsers className='OurServiceBodyIcon' />
                        <h2>UI/UX Design</h2>
                        <p>Creating visually stunning and user-friendly interfaces to elevate the user experience.</p>
                    </div>

                    <div className='OurService'>
                        <FaAssistiveListeningSystems className='OurServiceBodyIcon' />
                        <h2>Software Maintenance and Support</h2>
                        <p>Ensuring your software stays reliable and up-to-date with ongoing assistance and support.</p>
                    </div>

                    <div className='OurService'>
  <FaBullhorn className='OurServiceBodyIcon' /> {/* Updated icon */}
  <h2>Digital Marketing</h2>
  <p>Boost your online presence with targeted strategies in SEO, social media, content marketing, and advertising campaigns.</p>
</div>


                    <div className='OurService'>
                        <FaMoneyCheck className='OurServiceBodyIcon' />
                        <h2>E-commerce Solutions</h2>
                        <p>Developing powerful e-commerce platforms to help your business grow and succeed online.</p>
                    </div>

                    <div className='OurService'>
                        <FaDev className='OurServiceBodyIcon' />
                        <h2>API Development</h2>
                        <p>Designing and implementing robust APIs for seamless integration between systems.</p>
                    </div>

                    <div className='OurService'>
                        <FaMoneyBill className='OurServiceBodyIcon' />
                        <h2>Payment Integration</h2>
                        <p>Integrating secure payment gateways for smooth financial transactions on your platforms.</p>
                    </div>
                </div>

                <div className='ContactUsNowWrap'>
                    <h1 onClick={handleNavigate}>Contact Us Now for Your Projects</h1>
                </div>
            </div>
        </div>
    )
}

export default Gigs;
