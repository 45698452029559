// import React, { useContext,useState } from 'react'
// // import "../CSS/SpecialOffer.css"
// import "../CSS2/SpecialOffer2.css"
// import "animate.css"
// import {FaClosedCaptioning, FaHome, FaInternetExplorer, FaRegWindowClose, FaUser, FaUsers} from "react-icons/fa"
// import { useNavigate } from 'react-router-dom'
// import { Context } from './Contex'

// const SpecialOffer = () => {
//   const {}=useContext(Context)
//   const navigate = useNavigate()
//   const handleNavigate = () => {
//     navigate("/bussinesswebsiteoffer")
    
//   }

//   const handleNavigate2 = () => {
//     navigate("/portfoliowebsiteoffer")
//   }

  

//   const [portfolioSamplesOption,setPortfolioSamplesOption]=useState(false)
//   const [sampleContents,setSampleContents]=useState("View Samples")

//   const handlePortfolioSampleOption = ()=>{
//     setPortfolioSamplesOption(!portfolioSamplesOption)
//     if(portfolioSamplesOption){
//       setSampleContents("View Samples")
//     }else{
//       setSampleContents("View less...")
//     }
//   }

//   const handleNavigate3 = (url)=>{
//     window.open(url,"_blank")
//   }

//   const handleNavigate4 = ()=>{
//     navigate("/forntendtrainingdetailpage")
//   }

//   const handleNavigate5 = ()=>{
//     navigate("/uiuxtrainingdetailpage")
//   }
//   return (
//     <div className='SpecialOfferWrap'>
//         <div className='SpecialOffers'>
//             <div className='SpecialOffersHeader'>
//                 <h1 class="animate__animated animate__tada">Special Offers for you!!!</h1>
//             </div>
            
//             <div className='SpecialOffersBody'>
//             <div className='SpecialOffer'>
//                 <FaHome className='SpecialOfferIcon'/>
//                 <p>We shall <span style={{fontWeight:"bold",color:"blue",textDecoration:"underline"}}>BUILD A WEBSITE FOR YOUR BUSINESS</span> for as low as 10,000 naira 
//                   (aside hosting and domain name); and you get to pay after Serivce.
//                 </p>
//                 <p>Offer Valid for First 50 people.</p>
//                 <button class="animate__animated animate__bounce" onClick={handleNavigate}> Place your order now</button>
//             </div>
//             <div className='SpecialOffer'>
//             <FaUser className='SpecialOfferIcon'/>
//                 <p>Instead of sticking to the old outdated regular CV, we shall <span style={{fontWeight:"bold",color:"blue",textDecoration:"underline"}}>BUILD A PROFESSIONAL PORTFOLIO WEBSITE</span> for you to showcase your 
//                   Qualifications and experiences in an interractive manner with just 10,000 naira 
//                   (aside hosting and domian name); and you get to pay after Serivce.
//                 </p>
//                 <p>Offer Valid for First 50 people.</p>
//                 <div className='ViewSampleAndButtonWrap'>
//                     <p onClick={handlePortfolioSampleOption}>{sampleContents}</p>
                    
//                     <button class="animate__animated animate__bounce"
//                     onClick={handleNavigate2} >Place your order now</button>
//                 </div>
//                 {portfolioSamplesOption&&<div className='PortfolioWebsiteSamplesOption'>
//                         <p onClick={()=>handleNavigate3("https://victorndusoftwareengineer.vercel.app/")}>View Victor Ndu's Portfolio</p>
//                         <p>View Ambassador's Portfolio</p>  
//                     </div>}
//             </div>
//             <div className='SpecialOffer'>
//             <FaInternetExplorer className='SpecialOfferIcon'/>
//                 <p><span style={{fontWeight:"bold",color:"blue",textDecoration:"underline"}}>BECOME A FRONT-END WEB DEVELOPER</span> with certification in 3 months with our
//                    Comprehensive Training program for just 50,000 naira and you can pay in 3 installments.
//                   Offer valid for first 10 people.</p>
               
//                 <button class="animate__animated animate__bounce"
//                 onClick={handleNavigate4}
//                 >Book your slot for free now</button>
//             </div>
//             <div className='SpecialOffer'>
//             <FaUsers className='SpecialOfferIcon'/>
//                 <p><span style={{fontWeight:"bold",color:"blue",textDecoration:"underline"}}>LEARN UIUX / PRODUCT DESIGN</span> with certification in 2 months with our comprehensive
//                    Training program for just 30,000 naira and you can pay in 2 installments.
//                   Offer valid for first 10 people.</p>

//                 <button class="animate__animated animate__bounce"
//                 onClick={handleNavigate5}>Book your slot for free now</button>
//             </div>
//             </div>
//         </div>
      
//     </div>
//   )
// }

// export default SpecialOffer;
import React, { useContext, useState } from 'react';
import "../CSS2/SpecialOffer2.css"; // Ensure this CSS file is updated
import { FaClosedCaptioning, FaHome, FaInternetExplorer, FaPaintBrush, FaUser, FaUsers } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Context } from './Contex';
import { FaCamera, FaDeskpro, FaDigitalOcean, FaLaptop } from 'react-icons/fa6';

const SpecialOffer = () => {
  const {} = useContext(Context);
  const navigate = useNavigate();
  
  const [portfolioSamplesVisible, setPortfolioSamplesVisible] = useState(false);
  const [sampleButtonText, setSampleButtonText] = useState("View Samples");

  const togglePortfolioSamples = () => {
    setPortfolioSamplesVisible(!portfolioSamplesVisible);
    setSampleButtonText(portfolioSamplesVisible ? "View Samples" : "View less...");
  };

  const navigateToExternal = (url) => {
    window.open(url, "_blank");
  };

  const navigateToPage = (path) => {
    navigate(path);
  };

  return (
    <div className="special-offer-section">
      <h1 className="section-title">Exclusive Offers Just for You!</h1>
      <div className="offer-cards">

        {/* Offer 1 */}
        <div className="offer-card">
          <FaHome className='offer-icon' />
          <h2 className="offer-title">Build a Website</h2>
          <p>Get your business online for just <strong>₦50,000</strong> (excludes hosting & domain). Pay after service!</p>
          <p className="validity">Offer valid for the first 50 customers.</p>
          <button className="order-button" onClick={() => navigateToPage("/bussinesswebsiteoffer")}>Order Now</button>
        </div>

        {/* Offer 2 */}
        <div className="offer-card">
          <FaUser className='offer-icon' />
          <h2 className="offer-title">Professional Portfolio Website</h2>
          <p>Stand out with a personal portfolio website for only <strong>₦50,000</strong> (excludes hosting & domain). Pay after service!</p>
          <p className="validity">Offer valid for the first 50 customers.</p>
          <div className='view-samples-wrap'>
            <p className='sample-toggle' onClick={togglePortfolioSamples}>{sampleButtonText}</p>
            <button className="order-button" onClick={() => navigateToPage("/portfoliowebsiteoffer")}>Order Now</button>
          </div>
          {portfolioSamplesVisible && (
            <div className='portfolio-samples'>
              <p onClick={() => navigateToExternal("https://victorndusoftwareengineer.vercel.app/")}>Victor Ndu's Portfolio</p><br/>
              <p onClick={() => navigateToExternal("https://engineerchinedujosephndu.vercel.app/")} >Ambassador's Portfolio</p>
            </div>
          )}
        </div>

        {/* Offer 3 */}
        <div className="offer-card">
          <FaInternetExplorer className='offer-icon' />
          <h2 className="offer-title">Front-End Web Development Training</h2>
          <p>Become a certified developer in 4 months for just <strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        {/* Offer 4 */}
        <div className="offer-card">
          <FaUsers className='offer-icon' />
          <h2 className="offer-title">Product Design Training</h2>
          <p>Learn Product in 3 months with certification for only <strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaPaintBrush className='offer-icon' />
          <h2 className="offer-title">Graphics Design Training</h2>
          <p>Learn Graphics Design in 3 months with certification for only <strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaDeskpro className='offer-icon' />
          <h2 className="offer-title">Backend Development Training</h2>
          <p>Learn Backend Development for<strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaLaptop className='offer-icon' />
          <h2 className="offer-title">Computer Basics and Appreciation Training</h2>
          <p>Price: <strong>₦30,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaCamera className='offer-icon' />
          <h2 className="offer-title">Content Creation, video Editing and Social media management Training</h2>
          <p>Price: <strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaDigitalOcean className='offer-icon' />
          <h2 className="offer-title">Digital Marketing Training</h2>
          <p>Price: <strong>₦50,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

        <div className="offer-card">
          <FaDigitalOcean className='offer-icon' />
          <h2 className="offer-title">E-Commerce and Dropshipping Training</h2>
          <p>Price: <strong>₦30,000</strong>. Offer valid for the first 10 people.</p>
          <button className="order-button" onClick={() => navigateToPage("/trainingregistrationpage")}>Register Now</button>
        </div>

      </div>
    </div>
  );
};

export default SpecialOffer;
