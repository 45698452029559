import React, { useContext, useState } from 'react';
import "../CSS/FrontEndTrainingDetailPage.css"; // Using the same CSS file for styling
import VicPortFoliogif from "../Images/Vic portfolio gif.gif";
import { FaBackward } from 'react-icons/fa6';
import { Context } from './Contex';
import TermsAndConditions from './TermsAndConditions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const PortfolioWebsiteOffer = () => {
    const { showTermsAndConditionsUI, setShowTermsAndConditionsUI } = useContext(Context);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        orderName: "Build Your Portfolio Website",
        fullName: "",
        email: "",
        phoneNumber: "",
        confirmPhoneNumber: "",
        address: "",
   
    });

    const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
    const [isError, setIsError] = useState(false); // Track errors in form submission

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        if (formData.phoneNumber !== formData.confirmPhoneNumber) {
            Swal.fire({ text: "Phone numbers do not match" });
            return;
        }

        const loadingAlert = Swal.fire({ text: "Please wait..." });
        Swal.showLoading();

        try {
            const response = await fetch('https://formspree.io/f/mnqerjbz', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setIsSubmitted(true);
                setFormData({
                    orderName: "Build Your Portfolio Website",
                    fullName: "",
                    email: "",
                    phoneNumber: "",
                    confirmPhoneNumber: "",
                    address: "",
                    
                });
                setIsError(false);
                Swal.fire({ icon: "success", text: "Your request has been submitted and one of our representatives will get back to you soon." });
                navigate("/");
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        } finally {
            loadingAlert.close();
        }
    };

    return (
        <div className='FrontEndTrainingOfferWrap'> {/* Use same outer wrapper class */}
            <div className='FrontEndTrainingOffer'> {/* Same structure */}
                <div className='FrontEndTrainingOfferHeader'>
                    <h1>Build Your Portfolio Website</h1>
                </div>
                <div className='FrontEndTrainingImgWrap'>
                    <img src={VicPortFoliogif} alt="Website" />
                </div>
                <div className='FrontEndTrainingOfferBody'>
                    <div className='FrontEndTrainingOfferBodyRight'>
                        <p>
                            Instead of sticking to the old outdated regular CV, we shall build for you a 
                            professional Portfolio Website to showcase your qualifications and experiences in 
                            an interactive manner with just 50,000 Naira (aside hosting and domain name); 
                            and you get to pay after service.
                            <br /><br />
                            Offer valid for the first 50 people.
                            <br /><br />
                            Just fill your details in the form and a brief message in the respective fields;
                            One of our team representatives shall contact you shortly for further details. Thanks.
                        </p>
                    </div>
                    <div className='FrontEndTrainingOfferBodyLeft'>
                        <form onSubmit={handleSubmit}>
                            <div className='FrontEndTrainingformNote'>
                                <p>Please fill in the form, agree to the terms and conditions, and click submit.</p>
                                <p 
                                    style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={() => setShowTermsAndConditionsUI(true)}
                                >
                                    Click to read Terms and Conditions
                                </p>
                            </div>
                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input type="text" name="Order Name" value={formData.orderName} readOnly />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input 
                                        type="text" 
                                        name="Full Name" 
                                        value={formData.fullName} 
                                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} 
                                        placeholder='Enter Full Name' 
                                        required 
                                    />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input 
                                        type="email" 
                                        name="Email" 
                                        value={formData.email} 
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
                                        placeholder='Email Address' 
                                        required 
                                    />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <input 
                                        type="text" 
                                        name='Phone Number' 
                                        value={formData.phoneNumber} 
                                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })} 
                                        placeholder="Phone Number" 
                                        required 
                                    />
                                </div>
                            </label>

                            <label>
                                <div className="FrontEndTrainingOfferInputLabelChild">
                                    <input 
                                        type="text" 
                                        value={formData.confirmPhoneNumber} 
                                        onChange={(e) => setFormData({ ...formData, confirmPhoneNumber: e.target.value })} 
                                        placeholder="Confirm phone number" 
                                        required 
                                    />
                                </div>
                            </label>

                            <label>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                             
                                    <input 
                                        type="text" 
                                        name="Address" 
                                        value={formData.address} 
                                        onChange={(e) => setFormData({ ...formData, address: e.target.value })} 
                                        placeholder="Address" 
                                        required 
                                    />
                                </div>
                            </label>
{/* 
                            <div className='FrontEndTrainingOfferTextAreaWrap'>
                                <div className='FrontEndTrainingOfferInputLabelChild'>
                                    <p>Message:</p>
                                    <textarea 
                                        type="text" 
                                        name="Message" 
                                        value={formData.message} 
                                        onChange={(e) => setFormData({ ...formData, message: e.target.value })} 
                                        placeholder='Please enter your message here' 
                                        required 
                                    />
                                </div>
                            </div> */}

                            <div className='FrontEndTrainingOfferTermsAndConditionsAgreeWrap'>
                                <div className='FrontEndTrainingOfferTermsAndConditionsAgreeWrapUp'>
                                    <input 
                                        type="checkbox" 
                                        required 
                                    />
                                    <p>
                                        I agree to <span onClick={() => setShowTermsAndConditionsUI(true)}>Terms and Conditions</span>
                                    </p>
                                </div>
                            </div>

                            <div className='FrontEndTrainingOfferSubmitButtonWrap'>
                                <button type="submit">Submit</button>
                            </div>

                            {isSubmitted && <p style={{ color: "green" }}>Thank you! Your submission has been received.</p>}
                            {isError && <p style={{ color: "red" }}>There was an error submitting the form. Please try again.</p>}
                        </form>
                    </div>
                </div>
                <br /><br />
                <div className='FrontEndTrainingOfferBackButtonWrap'>
                    <button onClick={() => window.history.back()}>
                        <FaBackward /> Back
                    </button>
                </div>
            </div>
            {showTermsAndConditionsUI && <TermsAndConditions />}
        </div>
    );
};

export default PortfolioWebsiteOffer;
