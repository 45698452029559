// import React from 'react'
// import facebook from "../Images/logos_facebook.png"
// // import "../CSS/Footer.css"
// import "../CSS2/Footer2.css"
// import phone from "../Images/teenyicons_phone-solid.png"
// import email from "../Images/ic_round-email.png"

// const Footer = () => {
//   return (
//     <div className='FooterWrap'>
//       <div className='Footer'>
//       <div className='FooterUpper'> 
//       <div className='FooterEmail'><img src={email}/><p>digitalpremiumtech@gmail.com</p></div>
//       <div className='FooterPhone'><img src={phone}/><p> +2347063480314</p></div>
//       <div className='FooterSocial'><img src={facebook} alt="socialIcon"/>digitalpremiumtech</div>
//       </div>
//       <div className='FooterLower'>
//         <h2>Digital Premium Tech:</h2>
//         <p>We are bold when we say that we are Genuine and the Best</p>
//       </div>
//       </div>
//     </div>
//   )
// }

// export default Footer
import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';  // Social Icons
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';  // Contact Icons
import "../CSS2/Footer2.css";

const Footer = () => {
  return (
    <div className='FooterWrap'>
      <div className='Footer'>
        
        {/* Footer Company Info Section */}
        <div className='FooterColumn'>
          <h3>About Us</h3>
          <p>Digital Premium Tech is your trusted partner in tech innovation, offering top-notch solutions for productivity and creativity.</p>
        </div>

        {/* Footer Quick Links Section */}
        <div className='FooterColumn'>
          <h3>Quick Links</h3>
          <ul>
            <li><a href='/'>Home</a></li>
            <li><a href='/aboutus'>About Us</a></li>
            {/* <li><a href='/services'>Services</a></li> */}
            <li><a href='/contactus'>Contact Us</a></li>
            <li><a href='/privacy-policy'>Privacy Policy</a></li>
            <li><a href='/terms-of-service'>Terms of Service</a></li>
          </ul>
        </div>

        {/* Footer Social Media Section */}
        <div className='FooterColumn'>
          <h3>Follow Us</h3>
          <div className='FooterSocials'>
            <a href='https://facebook.com'><FaFacebookF /></a>
            <a ><FaYoutube /></a>
            <a ><FaInstagram /></a>
            <a ><FaLinkedinIn /></a>
          </div>
        </div>

        {/* Footer Contact Section */}
        <div className='FooterColumn'>
          <h3>Contact Us</h3>
          <div className='FooterContact'>
            <MdEmail />
            <p>digitalpremiumtech@gmail.com</p>
          </div>
          <div className='FooterContact'>
            <MdPhone />
            <p>+2347063480314</p>
          </div>
          <div className='FooterContact'>
            <MdLocationOn />
            <p>Lagos, Nigeria</p>
          </div>
        </div>
      </div>

      {/* Footer Newsletter Section */}
      {/* <div className='FooterNewsletter'>
        <h3>Subscribe to our Newsletter</h3>
        <form className='NewsletterForm'>
          <input type='email' placeholder='Enter your email' required />
          <button type='submit'>Subscribe</button>
        </form>
      </div> */}

      {/* Footer Bottom Section */}
      <div className='FooterBottom'>
        <p>&copy; {new Date().getFullYear()} Digital Premium Tech. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
